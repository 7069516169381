<template>
  <div class="container mt-5">
    <div class="playingCards">
      <ul class="cards hand" v-for="color in colors">
        <card v-for="number in numbers" :key="number" :data="{open:true, color, value: number}"
              :canplay="canPlayCard"
              :playcard="playCard"
              :forceopen="false"
              :marked="false"></card>
      </ul>
      <ul class="cards hand">
        <card v-for="(extra, index) in extras" :key="index" :data="{open:true, color:null, value: extra}"
              :canplay="canPlayCard"
              :playcard="playCard"
              :forceopen="false"
              :marked="false"></card>
      </ul>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'

export default {
  name: 'Demo',
  data() {
    return {
      colors: ['blue', 'green', 'red', 'yellow', 'violet', 'orange'],
      numbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      extras: ['J', 'J', 'X', 'X', 'X', 'X', '!', '!', '!', '!', '+5', '+5', '+5', '-5', '-5', '-5']
    }
  },
  methods: {
    canPlayCard(card) {
      return true
    },
    playCard(card) {
    }
  },
  components: {
    Card
  }
}
</script>
