<template>
  <div class="container">
    <demo></demo>
  </div>
</template>


<script>
import Demo from '@/components/Demo.vue'

export default {
  name: 'About',
  components: {
    Demo
  }
}
</script>
